<template>
  <div v-if="question">
    <sqr-input-text
      label="Placeholder"
      :value="question.placeholder"
      @change="propSet('placeholder', $event)"
    />
  </div>
</template>

<script>
import base from '../base';
import SqrInputText from '@/sqrd-ui/SqrInputText';
export default {
  name: 'EmailEdit',
  mixins: [base],
  components: { SqrInputText }
};
</script>
